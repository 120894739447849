import './App.css';
import {Admin, Resource} from "react-admin";
import {dataProvider} from "./dataProvider";
import authProvider from "./authProvider";
import {UserCreate, UserList, UserShow, UserUpdate} from "./pages/user";
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {ResetPassword} from "./pages/reset";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ExLayout} from "./components/ExLayout";
import {AccountCreate, AccountList, AccountShow} from "./pages/account";
import {OperationCreate, OperationUpdate} from "./pages/operation";

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/*" element={
                <ReactAdmin/>
            }/>
            <Route path="/reset/password" element={<ResetPassword/>}/>
        </Routes>

    </BrowserRouter>
);

const ReactAdmin = () => (
    <Admin layout={ExLayout} dataProvider={dataProvider} authProvider={authProvider} requireAuth>
        { permissions => (
            <>
                { permissions.includes("ADMIN") ?
                    <>
                        <Resource options={{label: 'Dipendenti'}} icon={PeopleIcon} name="user" list={UserList} create={UserCreate} edit={UserUpdate} show={UserShow}/>
                        <Resource options={{label: 'Conti'}} icon={AttachMoneyIcon} name="account" list={AccountList} create={AccountCreate} show={AccountShow}/>
                        <Resource name="operation" create={OperationCreate} edit={OperationUpdate}/>
                    </>
                    : null
                }
            </>
        ) }
    </Admin>
)

export default App;
