import {AppBar, TitlePortal} from "react-admin";
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const SettingsButton = () => (
    <IconButton color="inherit">
        <SettingsIcon />
    </IconButton>
);

export const ExAppBar = () => {
    return <AppBar color="primary">
        <TitlePortal/>
        <SettingsButton/>
    </AppBar>
}