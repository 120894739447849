import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {baseUrlApi} from "../environment";

export const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState("")
    const [passwordCheck, setPasswordCheck] = useState("")

    useEffect(() => {
        if (!searchParams.get("id") || searchParams.get("id") === "") {
            window.location.replace(baseUrlApi + "/login")
        } else {
            localStorage.removeItem('auth');
        }
    });

    const handleChangePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleChangePasswordCheck = (event) => {
        setPasswordCheck(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const request = new Request(baseUrlApi + '/api/v1/reset/password/' + searchParams.get("id"), {
            method: 'POST',
            body: JSON.stringify({ "password": password, "password_check": passwordCheck }),
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
        });

        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                alert("Password cambiata con successo!")
                window.location.replace(baseUrlApi + "/login")
            })
            .catch(() => {
                alert("Errore durante il cambiamento della password")
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Password:
                <input type="password" value={password} onChange={handleChangePassword} />
            </label>
            <label>
                Controllo password:
                <input type="password" value={passwordCheck} onChange={handleChangePasswordCheck} />
            </label>
            <input type="submit" value="Invia" />
        </form>
        )
}
