import {
    ChipField,
    Create,
    Datagrid, DateField,
    Edit,
    email,
    List,
    minLength,
    required, SelectArrayInput, SelectInput, Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from "react-admin";

export const UserList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="name.String" label="Nome"/>
            <TextField source="surname.String" label="Cognome"/>
            <TextField source="email" label="Email"/>
            <TextField source="username" label="Username"/>
            <TextField source="roles" label="Permessi"/>
            <DateField source="last_login_at" label="Ultimo Login"/>
            <ChipField source="state" label="Stato"/>
        </Datagrid>
    </List>
)

export const UserCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name" name="name" label="Nome" validate={[required()]}/>
            <TextInput source="surname" name="surname" label="Cognome" validate={[required()]}/>
            <TextInput source="username" name="username" label="Username" validate={[required()]}/>
            <TextInput source="email" name="email" label="E-Mail" validate={[required(), minLength(5), email()]}/>
        </SimpleForm>
    </Create>
)

export const UserUpdate = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name" name="name.String" label="Nome" validate={[required()]}/>
            <TextInput source="surname" name="surname.String" label="Cognome" validate={[required()]}/>
            <TextInput source="username" name="username" label="Username" validate={[required()]}/>
            <TextInput source="email" name="email" label="E-Mail" validate={[required(), minLength(5), email()]}/>
            <SelectArrayInput source="roles" name="roles" label="Permessi" choices={[
                {id: 'ADMIN', name: 'ADMIN'},
                {id: 'USER', name: 'USER'}
            ]}/>
            <SelectInput name="state" source="state" choices={[
                { id: 'ACTIVE', name: 'ACTIVE' },
                { id: 'DISABLED', name: 'DISABLED' },
            ]}/>
        </SimpleForm>
    </Edit>
)

export const UserShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="name.String" label="Nome"/>
            <TextField source="surname.String" label="Cognome"/>
            <TextField source="email" label="Email"/>
            <TextField source="username" label="Username"/>
            <TextField source="roles" label="Permessi"/>
            <TextField source="last_login_at" label="Ultimo Login"/>
            <TextField source="state" label="Stato utente"/>
        </SimpleShowLayout>
    </Show>
)