import {
    Create,
    DateTimeInput, Edit, getRecordFromLocation,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useNotify, useRedirect
} from "react-admin";
import {useLocation} from "react-router-dom";

export const OperationCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const location = useLocation();

    const onSuccess = () => {
        // display a notification to confirm the creation
        notify('ra.notification.created');
        const record = getRecordFromLocation(location);
        if (record && record.account_id) {
            redirect(`/account/${record.account_id}/show`);
        } else {
            redirect(`/account`);
        }
    };

    return <Create mutationOptions={{ onSuccess }}>
        <SimpleForm>
            <DateTimeInput source="dated_at" name="dated_at" label="Data inserimento" validate={[required()]}/>
            <TextInput source="causal" name="causal" label="Causuale" validate={[required()]}/>
            <TextInput source="customer_denomination" name="customer_denomination" label="Denominazione cliente"
                       validate={[required()]}/>
            <NumberInput source="in" name="in" label="Ingresso"/>
            <NumberInput source="out" name="out" label="Uscita"/>
            <DateTimeInput source="expire_at" name="expire_at" label="Scadenza"/>
            <SelectInput name="state" source="state" choices={[
                {id: "INVOICED", name: "FATTURATA"},
                {id: "PENDING", name: "IN ATTESA"},
                {id: "PAYED", name: "PAGATA"}
            ]}/>
        </SimpleForm>
    </Create>
}

export const OperationUpdate = () => (
    <Edit>
        <SimpleForm>
            <DateTimeInput source="dated_at" name="dated_at" label="Data inserimento" validate={[required()]}/>
            <TextInput source="causal" name="causal" label="Causuale" validate={[required()]}/>
            <TextInput source="customer_denomination" name="customer_denomination" label="Denominazione cliente"
                       validate={[required()]}/>
            <NumberInput source="in" name="in" label="Ingresso"/>
            <NumberInput source="out" name="out" label="Uscita"/>
            <DateTimeInput source="expire_at" name="expire_at" label="Scadenza"/>
            <SelectInput name="state" source="state" choices={[
                {id: "INVOICED", name: "FATTURATA"},
                {id: "PENDING", name: "IN ATTESA"},
                {id: "PAYED", name: "PAGATA"}
            ]}/>
        </SimpleForm>
    </Edit>
)