import jwt_decode from "jwt-decode";
import {baseUrlApi} from "./environment";

const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request(baseUrlApi + '/api/v1/login', {
            method: 'POST',
            body: JSON.stringify({ "email": username, "password": password }),
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth))
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject()
    },
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const decodeJwt = jwt_decode(JSON.parse(localStorage.getItem("auth"))["access_token"]);
            return Promise.resolve({id: decodeJwt["user_id"], fullName: decodeJwt["username"]});
        } catch (error) {
            return Promise.reject(error)
        }
    },
    getPermissions: () => {
        const role = JSON.parse(localStorage.getItem("auth"))["roles"];
        return role ? Promise.resolve(role) : Promise.reject();
    }
};

export default authProvider;