import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import {baseUrlApi} from "./environment";
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const auth  = JSON.parse(localStorage.getItem('auth'));
    options.headers.set('Authorization', `Bearer ${auth["access_token"]}`);
    return fetchUtils.fetchJson(url, options);
};
export const dataProvider = jsonServerProvider(baseUrlApi + '/api/v1', httpClient);
