import {
    ChipField,
    Create, CreateButton,
    Datagrid, DateField, DateTimeInput, DeleteButton, EditButton,
    List,
    minLength, NumberField, ReferenceManyField,
    required, SelectInput, Show,
    SimpleForm, SimpleShowLayout,
    TextField,
    TextInput, useRecordContext
} from "react-admin";

export const CreateRelatedReviewButton = () => {
    const account = useRecordContext();
    return (
        <CreateButton
            resource="operation"
            state={{ record: { account_id: account.id } }}
        />
    );
};

export const AccountList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="name" label="Nome"/>
            <TextField source="bank_name" label="Banca"/>
            <TextField source="iban" label="IBAN"/>
            <DateField source="opened_at" label="Aperto il"/>
            <NumberField source="total_value" label="Valore"/>
            <ChipField source="state" label="Stato"/>
        </Datagrid>
    </List>
)

export const AccountCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name" name="name" label="Nome" validate={[required()]}/>
            <TextInput source="bank_name" name="bank_name" label="Nome della banca" validate={[required()]}/>
            <TextInput source="iban" name="iban" label="IBAN" validate={[required(), minLength(27)]}/>
            <DateTimeInput source="opened_at" name="opened_at" label="Aperto il" validate={[required()]}/>
            <SelectInput name="state" source="state" choices={[
                { id: "OPEN", name: "APERTO" },
                { id: "CLOSED", name: "CHIUSO" }
            ]}/>
        </SimpleForm>
    </Create>
)

export const AccountShow = () => (
    <Show>
        <SimpleShowLayout>
            <CreateRelatedReviewButton/>
            <ReferenceManyField reference="operation" target="account_id">
                <Datagrid>
                    <DateField source="dated_at" label="Data"/>
                    <TextField source="causal" label="Causale"/>
                    <TextField source="customer_denomination" label="Nome cliente"/>
                    <NumberField source="in" label="Ingresso"/>
                    <NumberField source="out" label="Uscita"/>
                    <DateField source="expire_at" name="expire_at" label="Scadenza"/>
                    <ChipField source="state" label="Stato"/>
                    <EditButton/>
                    <DeleteButton/>
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
)
